import React, { Component } from "react"
import Head from "../../head"

import Layout from "../../layout/layout.higherComponent"
import ClientsServices from "../servicesHomePage/servicesClients/clientsServices"
import TalkToOurTeam from "../../homepageInnos/about/TalkToOurTeam"
import TheLastTech from "../mobileApp/theLastTech/theLastTech"
import ProductPageTitle from "./productPageTitle/productPageTitle"
// import Contact from "../../homepageInnos/contact/contact.component"
// import ClientSuccess from "../mobileApp/clientsSuccessMobile/clientSuccess.component"
import OurProductServies from "./ourProductServices/ourProductServies"
// import LaverageProduct from "./laverageProduct/laverageProduct";
import OurProductProcess from "./ourProductProcess/ourProductProcess"

class ProductPage extends Component {
  constructor(props) {
    super(props)
    this.statsRef = React.createRef()
    this.statsRef2 = React.createRef()
  }
  handleScroll = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef.current.offsetTop,
      behavior: "smooth"
    })
  }
  handleScroll2 = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef2.current.offsetTop,
      behavior: "smooth"
    })
  }
  render() {
    return (
      <Layout>
        <Head title="Services" />
        <ProductPageTitle />
        <ClientsServices />
        <TalkToOurTeam />
        <OurProductServies />
        <TheLastTech />
        <OurProductProcess />

        {/* <div style={{ paddingTop: "70px" }}>
          <Contact />
        </div> */}
      </Layout>
    )
  }
}

export default ProductPage
