import React from "react"
import { withTranslation } from "react-i18next"
// import {dataProductTitle} from '../../../../data/Services/dataProduct'
import "./productPageTitle.scss"

const ProductPageTitle = props => {
  const { t } = props
  return (
    <section className="hero hero--600 bgProduct">
      <div className="container">
        <div className="hero__content">
          <h1 className="title title--uppercase">
            <span className="title__preheader title__preheader--tag">
              {t("src.pages.productPage.title")}
            </span>
            {t("src.pages.productPage.subtitle")}
          </h1>
          <p className="hero__lead">{t("src.pages.productPage.content")}</p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(ProductPageTitle)
