import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { useUnmount } from "react-use"
// import { dataTitleProductProcess } from "../../../../data/Services/dataProduct"
import DotsMobile from "../../mobileApp/ourMobileProcess/dotsMobile/dotsMobile"
import ContentMobile from "../../mobileApp/ourMobileProcess/contentMobile/contentMobile"
const OurProductProcess = props => {
  const { t } = props

  const [addictionalClasses, setClasses] = useState("")

  function checkScreen() {
    if (window.scrollY > 2700 && window.scrollY < 3500) setClasses("in-view")
    else {
      setClasses("")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScreen)
  }, [])

  useUnmount(() => {
    window.removeEventListener("scroll", checkScreen)
  })

  return (
    <section className="service-dev-team">
      <div className="container">
        <h2 className="dev-team__title">
          {t("src.pages.productPage.ourProductProcess.title")}
        </h2>
        <p className="dev-team__description">
          {t("src.pages.productPage.ourProductProcess.content")}
        </p>

        <div className="dev-team__row">
          <DotsMobile show={addictionalClasses} />
          <ContentMobile show={addictionalClasses} />
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(OurProductProcess)
