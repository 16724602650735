import React from "react"
import { withTranslation } from "react-i18next"
import "./ourProductServices.scss"
// import { dataOurProduct, dataOurProductTitle } from "../../../../data/Services/dataProduct"

const OurProductServies = props => {
  const { t } = props
  return (
    <>
      <section className="service-why">
        <h1 className="service-why__title service-why__title--team-extension">
          {t("src.pages.productPage.ourProductServices.title")}
        </h1>
        <p className="service-why__description">
          {t("src.pages.productPage.ourProductServices.content")}
        </p>
        <section className="service-why__points">
          {t("src.pages.productPage.ourProductServices.data").map(
            (block, index) => (
              <div
                key={index}
                className={`service-why__point-block ourProduct${index + 1}`}
              >
                <div className="service-why__mask"></div>
                <h2 className="service-why__point-title">{block.title}</h2>
                <div className="service-why__point-lead-content">
                  <p className="service-why__point-lead">{block.content}</p>
                </div>
              </div>
            )
          )}
        </section>
      </section>
    </>
  )
}

export default withTranslation()(OurProductServies)
