import React from "react"
import ProductPage from '../components/servicesPage/product/productPage'

const Product = () => {
  return (
    <ProductPage/>
  )
}

export default Product
